import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';
import CoolersPage from './pages/CoolersPage';
import MyCoolersPage from './pages/MyCoolersPage';

import MembersPage from './pages/MembersPage';

import RegisterPage from './pages/RegisterPage';

import InboxPage from './pages/InboxPage';
import SettingsPage from './pages/SettingsPage';

import StudentPage from './pages/StudentPage';
import ReportPage from './pages/ReportPage';
import ViewStudentPage from './pages/ViewStudentPage';
import TeacherPage from './pages/TeacherPage';
import EditStudentPage from './pages/EditStudentPage';
import EditTeacherPage from './pages/EditTeacherPage';
import ViewExamReportPage from './pages/ViewExamReportPage';
import LogoutPage from './pages/LogoutPage';

import NotificationsPage  from './pages/NotificationsPage';
import NotificationsDecoyPage from './pages/NotificationsDecoyPage';
import ViewAttendanceReportPage from './pages/ViewAttendanceReportPage';
import AddEditReportPage from './pages/AddEditReportPage';
import PaymentCallBackPageOM from './pages/PaymentCallBackOM';


export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'students', element: <StudentPage /> },
        { path: 'view-student', element: <ViewStudentPage /> },
        { path: 'view-exam-report', element: <ViewExamReportPage /> },
        { path: 'view-attendance-report', element: <ViewAttendanceReportPage /> },
        { path: 'edit-student', element: <EditStudentPage /> },
        { path: 'teachers', element: <TeacherPage /> },
        { path: 'edit-teacher', element: <EditTeacherPage /> },
        { path: 'reports', element: <ReportPage /> },
        { path: 'omcb', element: <PaymentCallBackPageOM /> },
        { path: 'action-reports', element: <AddEditReportPage /> },
        { path: 'members', element: <MembersPage /> },
        { path: 'my-cooler', element: <MyCoolersPage /> },
        { path: 'cooler', element: <CoolersPage /> },
       
        { path: 'chat', element: <InboxPage /> },
        { path: 'settings', element: <SettingsPage /> },

        { path: 'messages', element: <NotificationsPage /> },

        { path: 'messsages', element: <NotificationsDecoyPage /> },

        
      ],
    },
    { path: 'logout', element: <LogoutPage /> },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
