import { db, fb, auth, storage } from '../../config/firebase';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import { fetchStudents, fetchTeachers } from '../reducers/student.slice';

import firebase from "firebase/app";
import { doc } from 'prettier';
import { fetchUserData } from './auth.action';

export const uploadDocImages = (file) => async (dispatch) => {
  if (!file) {
    return null; 
  }

  const imageName = uuidv4() + '.' + file.name.split('.').pop();
  console.log('Upload Starting...: ', imageName);

  const uploadTask = storage.ref(`student_docs/${imageName}`).put(file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // setProgress(progress);
      },
      (error) => {
        console.log(error);
        reject(error);
      },
      () => {
        storage
          .ref('student_docs')
          .child(imageName)
          .getDownloadURL()
          .then((url) => {
            console.log('Image URL: ', url);
            resolve(url);
          })
          .catch((error) => reject(error));
      }
    );
  });
};


export const createStudentResult = async (data, navigate, setLoading) => {
  try {
    //setLoading(true);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();

    console.log("DATA:::", data);

    /*const resultRef =*/  db.collection('result').add({
        studentId: data.studentId,
        teacherId: data.teacherId,
        totalCumulative: data.totalCumulative,
        finalGrade: data.finalGrade,
        dateCreated: today.toLocaleDateString('en-US', options),
        subjects: data.scores,
      }).then(async(res)=>{ 

          db.collection('result').doc(res.id).update({
           resultId: res.id,
         });
  })

    console.log('Saved Successfully');
    notifySuccessFxn('Result Saved Successfully');
    navigate('/dashboard/home');
    //setLoading(true);
  } catch (error) {
    console.log('Error creating result:', error);
    notifyErrorFxn('Error creating result');
    //setLoading(false);
  }
};



export const createStudent =(studentData, navigate, setLoading)  =>  async(dispatch) => {
  try {
    setLoading(true);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();
    console.log('STUDENT_DATA:::', studentData);

    const studentRef = await db.collection('students').add({
      // registrationId: nanoid(8),
      fname: studentData?.state?.fname,
      lname: studentData?.state?.lname,
      dob: studentData?.state?.dob,
      gender: studentData?.state?.gender,
      studentshipType: studentData?.state?.studentshipType,
      registrationId: studentData?.state?.registrationId,
      class: studentData?.state?.class,
      section:studentData.state.class && (
        studentData.state.class =="Niveau 1"||
        studentData.state.class =="Niveau 2"||
        studentData.state.class =="Niveau 3"||
        studentData.state.class =="Niveau 4"||
        studentData.state.class =="Niveau 5"||
        studentData.state.class =="Niveau 6" ) 
       ? "Elementary":"Secondary",
      guardianName: studentData?.state?.guardianName,
      bloodGroup: studentData?.state?.bloodGroup,
      religion: studentData?.state?.religion,
      phoneNumber: studentData?.state?.phoneNumber,
      email: studentData?.state?.email,
      skinColor: studentData?.state?.skinColor,
      eyeColor: studentData?.state?.eyeColor,
      height: studentData?.state?.height,
      nationality: studentData?.state?.nationality,
      admissionDate: studentData?.state?.admissionDate,
      admissionTerminated: studentData?.state?.admissionTerminated,
      medicalHistory: studentData?.state?.medicalHistory,
      specialInstruction: studentData?.state?.specialInstruction,
      studentPassportFileUrl: studentData.studentPassportFileUrl,
      anotherFieldFileUrl: studentData.anotherFieldFileUrl,
      mothersIdFileUrl: studentData.mothersIdFileUrl,
      schoolId:studentData.schoolId,
      certificateFileUrl: studentData.certificateFileUrl,
      medicalRecordFileUrl: studentData.medicalRecordFileUrl,
      profileImageFileUrl: studentData.profileImageFileUrl,
      accountCreated: today.toLocaleDateString('en-US', options),
    });

    console.log('RESPONSE ID: ', studentRef.id);

    await db.collection('students').doc(studentRef.id).update({
      studentId: studentRef.id,
    });



   await db.collection('parents')
    .where('email', '==', studentData.state.email) // Adjust the field name if needed
    .get()
    .then(async(snapshot)=>{   

    console.log("PARENT EXISTS INFO--->",snapshot.docs)

     if(snapshot.docs.length >0 ){


      await db.collection('parents').doc(snapshot.docs[0].data().id).update({
        students: firebase.firestore.FieldValue.arrayUnion(studentRef.id)
      });

     }else{

  if(studentData.state.email){  

    fb.auth().createUserWithEmailAndPassword(
      studentData.state.email,
      '123456'
  ).then(async(res)=>{

    await db.collection('parents').doc(res.user.uid).set({
      id:res.user.uid,
      parentId:res.user.uid,
      schoolId:studentData.schoolId,
      accountCreated: today.toLocaleDateString('en-US', options),
      parentIdFileUrl: studentData.mothersIdFileUrl,
      email: studentData?.state?.email,
      password:'123456',
      phoneNumber: studentData?.state?.phoneNumber,
      students:[
        studentRef.id
      ],

       })



       dispatch(getStudents(studentData.schoolId))
     }


    )


  }else{


    await db.collection('parents').add({
      
      schoolId:studentData.schoolId,
      accountCreated: today.toLocaleDateString('en-US', options),
      parentIdFileUrl: studentData.mothersIdFileUrl,
      email: studentData?.state?.email,
      password:'123456',
      phoneNumber: studentData?.state?.phoneNumber,
      students:[
        studentRef.id
      ],

       }).then((res)=>{

        db.collection('parents')
      .doc(res.id)
      .update({
        id:res.user.id,
        parentId:res.user.id,
      })


      dispatch(getStudents(studentData.schoolId))
  
       })

  }


   }

  
})



    console.log('Created Successfully');
    notifySuccessFxn('Student Profile Created Successfully');
    navigate('/dashboard/home');
    setLoading(true);
  } catch (error) {
    console.log('Error creating student:', error);
    notifyErrorFxn('Error creating student profile');
    setLoading(false);
  }
};

export const getStudents = (schoolId) => async (dispatch) => {
  try {
    const studentsSnapshot = await db.collection('students')
    .where('schoolId', '==', schoolId)
    .get();

    const students = studentsSnapshot.docs.map((studentDoc) => {
      const studentData = studentDoc.data();
      return { id: studentDoc.id, ...studentData };
    });

    const studentsWithResultsPromises = students.map(async (student) => {
      const resultsSnapshot = await db.collection('result')
        .where('studentId', '==', student.studentId) // Adjust the field name if needed
        .get();

      const results = resultsSnapshot.docs.map((resultDoc) => resultDoc.data());
      return { ...student, results };
    });

    const studentsWithResults = await Promise.all(studentsWithResultsPromises);

    console.log('STUDENTS WITH RESULTS:', studentsWithResults);
    dispatch(fetchStudents(studentsWithResults));
  } catch (error) {
    console.error('Error fetching students and results:', error);
  }
};


export const deleteStudent = (studentData, navigate,user) => async (dispatch) => {

  db.collection('students')
  .doc(studentData.studentId)
  .delete(
    dispatch(getStudents(user?.schoolId))
  )
  .then((res)=>{
   
  })
  .then((res) => {
    //setLoading(false);
    notifySuccessFxn('Student has been deleted!');
     navigate('/dashboard/students', { replace: true });
  })
  .catch((err) => {
    //setLoading(false);
    console.log('ERR-: ', err);
  });

}

export const updateStudent = (studentData, navigate, setLoading,schoolId) => async (dispatch) => {
  console.log("STIDD IS---> ____", studentData);

  db.collection('students')
    .doc(studentData.state.studentId)
    .update({
      paymentStatus: studentData.state.paymentStatus ?? 'Unpaid',
      fname: studentData.state.fname,
      lname: studentData.state.lname,
      dob: studentData.state.dob,
      gender: studentData.state.gender,
      amountWillingToPay: studentData.state.amountWillingToPay,
      totalAmountOwed: Number(studentData.state.totalAmountOwed) - Number(studentData.state.amountPaidAlready) - Number(studentData.state.amountWillingToPay) >= 0?Number(studentData.state.totalAmountOwed) - Number(studentData.state.amountPaidAlready) - Number(studentData.state.amountWillingToPay) :0,
        amountPaidAlready:Number(studentData.state.amountPaidAlready) + Number(studentData.state.amountWillingToPay),
      //studentshipType: studentData.state.studentshipType,
      registrationId: studentData.state.registrationId,
      class: studentData.state.class,
      section:studentData.state.class && (
        studentData.state.class =="Niveau 1"||
        studentData.state.class =="Niveau 2"||
        studentData.state.class =="Niveau 3"||
        studentData.state.class =="Niveau 4"||
        studentData.state.class =="Niveau 5"||
        studentData.state.class =="Niveau 6" ) 
       ? "Elementary":"Secondary",
      //guardianName: studentData.state.guardianName,
      //bloodGroup: studentData.state.bloodGroup,
      //religion: studentData.state.religion,
      phoneNumber: studentData.state.phoneNumber,
      email: studentData.state.email,
      //skinColor: studentData.state.skinColor,
      //eyeColor: studentData.state.eyeColor,
      //height: studentData.state.height,
      nationality: studentData.state.nationality,
      admissionDate: studentData.state.admissionDate,
      admissionTerminated: studentData.state.admissionTerminated,
      medicalHistory: studentData.state.medicalHistory,
      specialInstruction: studentData.state.specialInstruction,
      //THE ONES THAT WONT BE IN STATE, BASED ON THE HANDLE UPDATE METHOD IN EDIT STUDENT PAGE
      studentPassportFileUrl: studentData.studentPassportFileUrl ?? "",
      anotherFieldFileUrl: studentData.anotherFieldFileUrl ?? "",
      mothersIdFileUrl: studentData.mothersIdFileUrl ?? "",
      certificateFileUrl: studentData.statecertificateFileUrl ?? "",
      medicalRecordFileUrl: studentData.medicalRecordFileUrl ?? "",
      profileImageFileUrl: studentData.profileImageFileUrl ?? "",
    }).then(()=>{

         
      db.collection('schools')
    .doc(schoolId)
    .get()
    .then((doc)=>{

      db.collection('schools')
    .doc(schoolId)
    .update({
     totalFeesReceived:Number(doc.data().totalFeesReceived) + Number(studentData.state.amountWillingToPay),
    })

    

     })

    })
    .then((res) => {
      dispatch(fetchUserData(schoolId))
      dispatch(getStudents(schoolId))
      setLoading(false);
      notifySuccessFxn('Updated successfully');
      // navigate('/dashboard/home', { replace: true });
    })
    .catch((err) => {
      setLoading(false);
      console.log('ERR-: ', err);
    });
};



export const updateStudentAttendance = (studentId,attendanceArray) => async (dispatch) => {
  db.collection('students')
    .doc(studentId)
    .update({

    attendance:attendanceArray

    }).then((res) => {
     
     console.log("updated attendance array!")
     
    })
    .catch((err) => {
     
      console.log('ERR updating student data-: ', err);
    });
};




export const createTeacher = async (teacherData, navigate, setLoading) => {
  try {
    setLoading(true);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();


    fb.auth().createUserWithEmailAndPassword(
      teacherData.state.email,
      '123456'
  ).then(async(res)=>{


    await db.collection('teachers').doc(res.user.uid).set({
      id:res.user.uid,
      teacherId:res.user.uid,
      fname: teacherData?.state?.fname,
      lname: teacherData?.state?.lname,
      email: teacherData?.state?.email,
      schoolId: teacherData?.state?.schoolId,
      dob: teacherData?.state?.dob,
      gender: teacherData?.state?.gender,
      phone: teacherData?.state?.phone,
      studentshipType: teacherData?.state?.studentshipType,
      registrationId: teacherData?.state?.registrationId,
      class: teacherData?.state?.class,
      section: teacherData?.state?.section,
      teacherPassportFileUrl: teacherData.teacherPassportFileUrl,
      accountCreated: today.toLocaleDateString('en-US', options),
    });

   // await db.collection('teachers').doc(teacherRef.id).update({
   //   teacherId: teacherRef.id,
   // });



    console.log('Created Successfully');
    notifySuccessFxn('Teacher Profile Created Successfully');
    navigate('/dashboard/home');
    setLoading(true);

})


  } catch (error) {
    console.log('Error creating teacher:', error);
    notifyErrorFxn('Error creating teacher profile');
    setLoading(false);
  }
};



export const getTeachers = (schoolId) => async (dispatch) => {
  db.collection('teachers')
    .where('schoolId', '==', schoolId)
    .get()
    .then((snapshot) => {
      const teachers = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      console.log("TEACHERSSS", teachers);
      dispatch(fetchTeachers(teachers));
    })
    .catch((error) => {
      var errorMessage = error.message;
      console.log('Error fetching teachers', errorMessage);
    });
};

export const updateTeacher = (teacherData, navigate, setLoading) => async (dispatch) => {
  console.log("TEACHHER_DATA", teacherData);

  db.collection('teachers')
    .doc(teacherData?.state?.teacherId)
    .update({
      fname: teacherData?.state?.fname,
      lname: teacherData?.state?.lname,
      email: teacherData?.state?.email,
      dob: teacherData?.state?.dob,
      gender: teacherData?.state?.gender,
      phone: teacherData?.state?.phone,
      studentshipType: teacherData?.state?.studentshipType,
      //registrationId: teacherData?.state?.registrationId,
      class: teacherData?.state?.class,
      level: teacherData?.state?.level,
      section: teacherData?.state?.section,
      // teacherPassportFileUrl: teacherData?.teacherPassportFileUrl ? teacherData.teacherPassportFileUrl : '',
    })
    .then((res) => {
      setLoading(false);
      notifySuccessFxn('Updated successfully');
      navigate('/dashboard/home', { replace: true });
    })
    .catch((err) => {
      setLoading(false);
      console.log('ERR-: ', err);
    });
};



export const deleteTeacher = (teacherData, navigate,user) => async (dispatch) => {

  db.collection('teachers')
  .doc(teacherData.teacherId)
  .delete(
    dispatch(getTeachers(user?.schoolId))
  )
  .then((res)=>{
   
  })
  .then((res) => {
    //setLoading(false);
    notifySuccessFxn('Teacher has been deleted!');
     navigate('/dashboard/teachers', { replace: true });
  })
  .catch((err) => {
    //setLoading(false);
    console.log('ERR-: ', err);
  });

}
