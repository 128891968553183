import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Chip } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTeacher, deleteTeacher, updateTeacher, uploadDocImages } from 'src/redux/actions/student.action';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  searchInput: {
    background: 'white',
    border: '1px solid #00000026',
    padding: '10px',
    borderRadius: '8px',
    // marginRight: theme.spacing(2),
    width: '100%',
    minWidth: '100%',
    '& .MuiInputBase-input': {
      color: 'grey',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'grey',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
  },
  searchButton: {
    color: '#fff',
    padding: '15px',
    minWidth: '45%',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const EditTeacherPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const teacherData = location.state?.teacher;
  const [state, setState] = useState({
    teacherId: teacherData.teacherId ?? '',
    fname: teacherData.fname ?? '',
    lname: teacherData.lname  ?? '',
    dob: teacherData.dob ?? '',
    email: teacherData.email ?? '',
    gender: teacherData.gender ?? '',
    phone: teacherData.phone ?? '',
   level: teacherData.level ?? '',
    studentshipType: teacherData.studentshipType ?? '',
    class: teacherData.class ?? '',
    level: teacherData.level?? '',
    section: teacherData.section ?? '',
  });

  const { themeColor } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);

  const [radiology2,setRadiology2] = useState([])
  const [radiology1,setRadiology1] = useState([])

  const [teacherPassportFile, setTeacherPassportFile] = useState({ selectedFile: [], selectedFileName: [] });
  const handleTeacherPassportFile = (event) => {
    setTeacherPassportFile({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name,
    });
  };


  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };




  const radiology1Setup = (e)=>{
   
 
    if(!radiology1.includes(e.target.value)){ 
      
      setRadiology1([...radiology1,/*targetCategoryTest[0].title*/e.target.value])
 
    setState({
      ...state,
      level: [...radiology1,/*targetCategoryTest[0].title*/e.target.value],
    });

     }
 
   }



  const radiology2Setup = (e)=>{
   
   // let   targetCategoryTest =  allTreatmentTests.filter((item)=>(item.uid === e.target.value )).length > 0 ? allTreatmentTests.filter((item)=>(item.uid === e.target.value )):[{title:null}]
    

   if(!radiology2.includes(e.target.value)){ setRadiology2([...radiology2,/*targetCategoryTest[0].title*/e.target.value])


   // if(!radiology2IdArray.includes(targetCategoryTest[0].uid)){setRadiology2IdArray([...radiology2IdArray,targetCategoryTest[0].uid])}

   
   setState({
     ...state,
     class: [...radiology2,/*targetCategoryTest[0].title*/e.target.value],
   });


  }
   
  }



  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleDelete2 = (tbr,tbrId) => {
    let placeholder =   radiology2.filter((item)=>(item !== tbr))
     //let placeholder2 =   radiology2IdArray.filter((item)=>(item !== tbrId))


      setRadiology2([...placeholder])
      //setRadiology2IdArray([...placeholder2])

      setState({
        ...state,
        class: [...placeholder],
      });
   
  };




  const handleDelete1 = (tbr,tbrId) => {
    let placeholder =   radiology1.filter((item)=>(item !== tbr))
     //let placeholder1 =   radiology2IdArray.filter((item)=>(item !== tbrId))


      setRadiology1([...placeholder])
      //setRadiology1IdArray([...placeholder2])

      setState({
        ...state,
        level: [...placeholder],
      });
   
      
  };








  const handleSubmit = async (e) => {
    e.preventDefault();

    const files = [teacherPassportFile.selectedFile];

    try {
      setLoading(true);
       if(teacherPassportFile?.selectedFile.length > 0){
        const urls = await Promise.all(files.map((file) => dispatch(uploadDocImages(file))));
        const [teacherPassportFileUrl] = urls;
        const teacherData = { state, teacherPassportFileUrl };
        dispatch(updateTeacher(teacherData, navigate, setLoading ))
       }else{
        const teacherData = { state };
        dispatch(updateTeacher(teacherData, navigate, setLoading ))
       }

    } catch (error) {
      console.error('Error uploading images: ', error);
    }
  };

 
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Prénom</Typography>
          <TextField
            name="fname"
            placeholder="Prénom"
            fullWidth
            value={state.fname}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Nom de famille</Typography>
          <TextField
            name="lname"
            placeholder="Nom de famille"
            fullWidth
            value={state.lname}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Date de Naissance</Typography>
          <TextField
            type="date"
            name="dob"
            placeholder="01/01/1999"
            fullWidth
            value={state.dob}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Gender</Typography>
          <select
            name="gender"
            value={state.gender}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value="">Sélectionnez le sexe</option>
            <option value="Mâle">Mâle</option>
            <option value="Femelle">Femelle</option>
          </select>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">Numéro de téléphone</Typography>
          <TextField
           
            name="phone"
            placeholder="numéro de téléphone"
            fullWidth
            value={state.phone}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>


        <Grid item xs={6}>
          <Typography variant="subtitle1">Niveau</Typography>
          {/*<TextField
            name="Niveau"
            type="text"
            placeholder="Niveau 1"
            fullWidth
            value={state.level}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />*/}

             
        <select
            name="Niveau"
            value={state.level}
            /*onChange={handleChange}*/
            onChange={(e)=>{handleChange(e);radiology1Setup(e)}}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value="">Select Level(s)</option>
            <option value="Niveau 1">Niveau 1</option>
            <option value="Niveau 2">Niveau 2</option>
            <option value="Niveau 3">Niveau 3</option>
            <option value="Niveau 4">Niveau 4</option>
            <option value="Niveau 5">Niveau 5</option>
            <option value="Niveau 6">Niveau 6</option>
            <option value="Niveau 7">Niveau 7</option>
            <option value="Niveau 8">Niveau 8</option>
            <option value="Niveau 9">Niveau 9</option>
            <option value="Niveau 10">Niveau 10</option>
            <option value="Niveau 11">Niveau 11</option>
            <option value="Niveau 12">Niveau 12</option>
            <option value="Niveau 13">Niveau 13</option>
          </select>
        </Grid>
        {/*<Grid item xs={6}>
          <Typography variant="subtitle1">Studentship Type</Typography>
          <select
            name="studentshipType"
            value={state.studentshipType}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value=""></option>
            <option value="Mâle">Boarding</option>
            <option value="Femelle">Day</option>
          </select>
        </Grid>*/}
        <Grid item xs={6}>
          <Typography variant="subtitle1">Class</Typography>
          <select
            name="class"
            value={state.class}
            /*onChange={handleChange}*/
            onChange={(e)=>{handleChange(e);radiology2Setup(e)}}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value="">sélectionner des cours</option>
            <option value="Biologee">Biologee</option>
            <option value="Historie">Historie</option>
            <option value="Mathematique">Mathematique</option>
            <option value="ECM">ECM</option>
            <option value="Physique">Physique</option>
            <option value="Anglais">Anglais</option>
            <option value="Economics">Economics</option>
            
          </select>
        </Grid>

        {/*START OF TWO FIELDS THAT LIST CLASSES AND LEVELS */}

        <Grid item xs={6}>
          <Typography variant="subtitle1">niveaux sélectionnés</Typography>
            <div style={{padding: '10px', border: radiology1 ? '1px solid #00000033' : ''}}>
             {radiology1  && 
              <> &nbsp; 
                {  radiology1.map((item,index)=>(
             <Chip label={item} onClick={handleClick} onDelete={()=>{handleDelete1(item)}} />
             ))
            }

             </>
             }

            </div>
        </Grid>
       
        <Grid item xs={6}>
          <Typography variant="subtitle1">Classes Selected</Typography>
          <div style={{padding: '10px', border: radiology2 ? '1px solid #00000033' : ''}}>
             {radiology2  && 
              <> &nbsp; 
                {  radiology2.map((item,index)=>(
             <Chip label={item} onClick={handleClick} onDelete={()=>{handleDelete2(item)}} />
             ))
            }

             </>
             }

            </div>
        </Grid>
     {/*END OF TWO FIELDS THAT LIST CLASSES AND LEVELS */}




        {/*<Grid item xs={6}>
          <Typography variant="subtitle1">Section</Typography>
          <select
            name="section"
            value={state.section}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value=""></option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </Grid>*/}
        <Grid item xs={6}>
          <Typography variant="subtitle1">Adresse e-mail</Typography>
          <TextField
            name="email"
            placeholder="Enter email"
            type="email"
            fullWidth
            value={state.email}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>

        <Grid item container xs={6} direction="row" alignItems="center">
          <Grid item xs={8}>
            <Typography variant="subtitle1">Teacher Passport (Format: PNG, JPEG, JPG)</Typography>
            <TextField
              placeholder="sélectionner un fichier"
              disabled
              fullWidth
              value={teacherPassportFile ? teacherPassportFile.selectedFileName : teacherData.teacherPassportFileUrl}
              onChange={handleChange}
              className={classes.searchInput}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              component="label"
              variant="contained"
              style={{
                minHeight: '52px',
                minWidth: '145px',
                backgroundColor: '#000000',
                marginTop: '20px',
              }}
            >
              <b>Choisir</b>
              <input type="file" style={{ display: 'none' }} onChange={handleTeacherPassportFile} />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleSubmit}
          style={{
            minWidth: '125px',
            backgroundColor: themeColor?themeColor:'#D72A34',
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '20px',
            marginLeft: '30px',
          }}
        >
          {loading ? 'Chargement...' : 'Modifier'}
        </Button>

        <Button
          disabled={loading}
          variant="contained"
          onClick={()=>{dispatch(deleteTeacher(teacherData, navigate,user))} }
          style={{
            minWidth: '125px',
            backgroundColor: '#000000',
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '20px',
            marginLeft: '30px',
          }}
        >
          {loading ? 'Chargement...' : 'Supprimer'}
        </Button>
      </Grid>
    </div>
  );
};

export default EditTeacherPage;
