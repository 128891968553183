import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, Paper, Button, MenuItem, FormControl, Select, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { fCurrency, fNumber } from '../utils/formatNumber';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EmptyRowCard from 'src/components/home/empty-row-card';
import SearchIcon from '@mui/icons-material/Search';
import { fetchUserData } from 'src/redux/actions/auth.action';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { fetchMyTransactions } from 'src/redux/actions/transaction.action';
import HomeCoolersCard from 'src/components/home/home-coolers-card';
import CustomSearchBar from 'src/components/global/CustomSearchBar';
import DashboardCard from 'src/components/home/dashboard-card';

import TeacherImg from '../assets/images/teacher2.png';


import FeesImg from '../assets/images/money_2.png';

import StudentImg from '../assets/images/student1.png';
import PieChartCard from 'src/components/home/pie-chart-card';
import CustomChart from 'src/components/home/custom-chart';
import { getStudents, getTeachers } from 'src/redux/actions/student.action';
import StudentFinanceStats from 'src/components/home/student-finance-stats';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';
import html2pdf from 'html2pdf.js';
import { clearPayTokenFromDatabase, fetchCartToProcessFromUser } from 'src/redux/actions/settings.action';
import axios from 'axios';


const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const CHART_DATA = [50, 50];

export default function HomePage() {
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user,school } = useSelector((state) => state.auth);
  const { myGroups, isLoading } = useSelector((state) => state.group);
  const { students,teachers } = useSelector((state) => state.student);

  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');

  const { themeColor } = useSelector((state) => state.settings);

  const { cart,cartToProcess,mostRecentOrderAmount,mostRecentOrderId,mostRecentPayToken} = useSelector((state) => state.settings);


  console.log("user IS-->",user)

  console.log("teachers IS-->",teachers)
  console.log("students IS-->",students)

   //const orangeTransactionUrl = 'http://localhost:5008/api/om/transaction'; <-- switch to this url when testing locally is involved
 const orangeTransactionUrl = 'https://vercel-code-14me.vercel.app/api/om/transaction';


 //const orangeMTokenUrl = 'http://localhost:5008/api/om/get-token'; <-- switch to this url when testing locally is involved
 // const orangeMPaymentUrl = 'http://localhost:5008/api/om/webpayment'; <-- switch to this url when testing locally is involved
  const orangeMTokenUrl = 'https://vercel-code-14me.vercel.app/api/om/get-token';
  const orangeMPaymentUrl = 'https://vercel-code-14me.vercel.app/api/om/webpayment';


  useEffect(()=>{

 if(!themeColor){
 dispatch(saveThemeColor( school && school.settings &&  school.settings.themeColor))
 dispatch(saveThemeImage(school && school.settings &&  school.settings.themeImage))
 }


  },[])


  useEffect(()=>{


    if(user && user.mostRecentPayToken && user.mostRecentPayToken.length){
      let userId = user && user.id
    
    
      dispatch(fetchCartToProcessFromUser(userId)).then(()=>{ 
       
        //console.log("I HAVE STEPPED PAST THE FUNCTION FOR FETCHING CART and PAY TOKEN NOW---> ")
        
    
        const headers = {
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*',  
        };
        
    axios.post(orangeMTokenUrl, {}, { headers })
     .then(response => {
         const access_token = response.data.access_token;
       
        axios.post(orangeTransactionUrl, {
         amount: mostRecentOrderAmount,
         order_id: mostRecentOrderId,
         payToken:mostRecentPayToken,
         orangeMToken: access_token
       }).then((res) => {
        
           //console.log("LOOK HERE FOR INITIATED --->", res.data);
           if (res.data.status && res.data.status === 'SUCCESS' ) {
             
             /*const cartObject = cartToProcess
             const courseIdArray =cartObject &&  cartObject.courses.map((item)=>(item.id))*/
             let today = new Date().toDateString();
           
             //console.log("COURSE ID ARRAY IS----->",courseIdArray)
            
           
               dispatch(clearPayTokenFromDatabase(userId))
            
             
              
    
    
           }else{
             //console.log("Res", res.data);
             //console.log("AT THE HOME PAGE, MOST RECENT ORANGE PAYMENT NOT SUCCESSFUL");  
             
           }
       }).catch((error) => {
        
         console.error('could not get transaction status, so this page failed:', error);
        
       })
     }).catch(error => {
        
      console.error('failed to get this token , to re-initiate payment so:', error);
        //notifyErrorFxn('Failed to get token');
     });
       
     
    
    })  
    
    
    }
    
    },[])


  // useEffect(() => {
  //   if(user?.id == undefined){
  //    return navigate("/login");
  //   }
  //  }, [])

 

  useEffect(() => {
    dispatch(getStudents(user && user.schoolId));
    dispatch(getTeachers(user?.schoolId));
    dispatch(fetchUserData(user?.id));
  }, []);


  useEffect(() => {

  setStudentsToDisplay(students)
 
},[students])

 const [maleTeachers,setMaleTeachers] =useState(teachers && teachers.filter((item)=>(item.gender && item.gender === "Male"|| item.gender ==="Mâle")).length )
 const [femaleTeachers,setFemaleTeachers] =useState(teachers && teachers.filter((item)=>(item.gender && item.gender === "Female" || item.gender === "Femelle")).length )

 const [maleStudents,setMaleStudents] =useState(students && students.filter((item)=>(item.gender && item.gender === "Male" ||item.gender ==="Mâle" )).length)
 const [femaleStudents,setFemaleStudents] =useState(students && students.filter((item)=>(item.gender && item.gender === "Female" || item.gender === "Femelle" )).length)

 console.log("maleStudent length",maleStudents)
 console.log("femaleStudent length",femaleStudents)
 console.log("maleTeachers length",maleTeachers)
 console.log("femaleTeachers length",femaleTeachers)

  const [levelFilter,setLevelFilter] = useState(false)
  const [genderFilter,setGenderFilter] = useState(false)
  const [statusFilter,setStatusFilter] = useState(false)

  const [mainMenuOpen,setMainMenuOpen] = useState(false)

  const [studentsToDisplay,setStudentsToDisplay] = useState([...students])
  

  const [selectedLevel, setSelectedLevel] = useState(false);
  const [selectedGender, setSelectedGender] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);



  useEffect(() => {
    /*FILTERING BY LEVEL */
         if(selectedLevel){
    
    
       let studentsFilteredByLevel = students.filter((item)=> (item.class && Number(item.class.slice(item.class.length-2,item.class.length)) ===  Number(selectedLevel.slice(selectedLevel.length-2,selectedLevel.length)) ))
      
        console.log("selected level last 2 digits--->",Number(selectedLevel.slice(selectedLevel.length-2,selectedLevel.length)))
    
       setStudentsToDisplay(studentsFilteredByLevel )
    
         }
    
    /*FILTERING BY GENDER */ 
         if(selectedGender){
    
    
          let studentsFilteredByGender = students.filter((item)=> (item.gender && item.gender===  selectedGender ))
         
           console.log("students filtered by level--->",studentsFilteredByGender)
       
          setStudentsToDisplay(studentsFilteredByGender)
       
            }
    
    
       /*FILTERING BY PAYMENT STATUS */   
       
       if(selectedStatus){
    
    
        let studentsFilteredByStatus = students.filter((item)=> (item.paymentStatus && item.paymentStatus ===  selectedStatus))
       
         console.log("students filtered by lpayment status--->",studentsFilteredByStatus)
     
        setStudentsToDisplay(studentsFilteredByStatus)
     
          }
    
    
        
    
        
      }, [selectedLevel,selectedGender,selectedStatus])



  const [selectedOption, setSelectedOption] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const [activeButton, setActiveButton] = useState('viewStudents');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedValue(event.target.value);
  };


  const handleSelectLevelChange = (event) => {
    setSelectedLevel(event.target.value);
   
  };

  const handleSelectGenderChange = (event) => {
    setSelectedGender(event.target.value);
   
  };


  const handleSelectStatusChange = (event) => {
    setSelectedStatus(event.target.value);
   
  };



  function printPageArea(areaID){
    var printContent = document.getElementById(areaID).innerHTML;
    var originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;

    setTimeout(window.location.reload(), 0);
}


const downloadPageContent = (areaID) => {
  // Get the HTML content of the current page
  const content = document.getElementById(areaID).outerHTML;

  const options = {
    margin: 0.5,
    filename: 'page.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: window.devicePixelRatio },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
  };

  // Convert the HTML content to PDF
  html2pdf().from(content).set(options).save();
};



  const handleSelectChange1 = (event) => {
  
    setSelectedValue(event.target.value);
  };



//  const myCoolerGroups = myGroups?.length ? (
//    myGroups
//      .slice(0, 3)
//      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
//      .map((group) => {
//        return (
//          <HomeCoolersCard
//            groupId={group.groupId}
//            name={group.groupName}
//            fee={fCurrency(group.amount)}
//            count={`${group.members.length} OF ${group.noOfSavers} SAVERS`}
//            img={group.imageUrl}
//            members={group.members}
//            isMember={group.members.includes(user?.id)}
//            startDate={group.startDate}
//          />
//        );
//      })
//  ) : (
//    <>
//      <EmptyRowCard msg={'Coolers you have joined will appear here.'} />
//    </>
//  );

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {/* First Grid Item */}
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
                border: '1px solid #F8F8F8',
                backgroundColor: '#F8F8F8',
                borderRadius: '10px',
              }}
            >
              <DashboardCard header={'Nombre total d\'enseignants'} value={teachers?.length ? teachers?.length : '0'} img={TeacherImg} />
            </Paper>
          </Grid>

          {/* Second Grid Item */}
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
                border: '1px solid #F8F8F8',
                backgroundColor: '#F8F8F8',
                borderRadius: '10px',
              }}
            >
              <DashboardCard
                header={'Nombre total d\'etudiants'}
                value={students?.length ? students?.length : '0'}
                img={StudentImg}
              />
            </Paper>
          </Grid>

          {/* Third Grid Item */}
          {/* <Grid item xs={12} md={8} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
              border: '1px solid #F8F8F8',
              backgroundColor: '#F8F8F8',
              borderRadius: '10px'
            }}
          >
            <DashboardCard header={'Total Number of Administrators'} value={"2"} img={AgentImg} />
          </Paper>
        </Grid> */}

          {/* Fourth Grid Item */}
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
                border: '1px solid #F8F8F8',
                backgroundColor: '#F8F8F8',
                borderRadius: '10px',
              }}
            >
              <DashboardCard header={'Total des frais'} value={user && user.totalFeesReceived >= 0 ?`$${((user.totalFeesReceived).toLocaleString())}`:'$20,000'} img={FeesImg}  />
            </Paper>
          </Grid>
        </Grid>
        <br />
        {/* <SearchBox style={{ width: '100%' }} /> */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <div style={{ background: '#F8F8F8', padding: '10px' }}>
              <PieChartCard
                headerOne={'Statistiques'}
                headerTwo={'Ratio (étudiants)'}
                maleNumber={maleStudents}
                femaleNumber={femaleStudents}
                value={((maleStudents/femaleStudents)>10000? (femaleStudents/maleStudents):(maleStudents/femaleStudents)).toFixed(2)}
                type={'one'}
              />
            </div>
          </Grid>

          <Grid item xs={8} md={12} lg={6}>
            <div style={{ background: '#F8F8F8', padding: '10px' }}>
              <PieChartCard
               maleNumber={maleTeachers}
               femaleNumber={femaleTeachers}
               headerOne={'Statistiques'} headerTwo={'Ratio (enseignants)'} value={((maleTeachers/femaleTeachers)>10000? (femaleTeachers/maleTeachers):(maleTeachers/femaleTeachers)).toFixed(2)} type={'two'} />
            </div>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 4 }}>
            <Box sx={{ width: '70%' }}>
              <Button
                variant={'contained'}
                style={{
                  minHeight: '50px',
                  minWidth: '180px',
                  backgroundColor: '#000000',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '20px',
                  marginRight: '4px',
                }}
                // onClick={handleOne}
              >
               Finance
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
              onClick={()=>{downloadPageContent("printableArea")}}
               variant="contained" style={{ minHeight: '50px', minWidth: '100px', backgroundColor: '#000000' }}>
                Exporter
              </Button>
              &nbsp; &nbsp;
              <Button
               onClick={()=>{printPageArea("printableArea");} }
              variant="contained" style={{ minHeight: '50px', minWidth: '100px', backgroundColor: themeColor?themeColor:"#D72A34" }}>
                Imprimer
              </Button>
            </Box>
          </Grid>

          <Grid container spacing={2} sx={{background: '#F8F8F8', padding: '10px'}}>
        <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140, background: 'white' }}>
          <Select
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
            Sélectionner une classe
            </MenuItem>
        <MenuItem value={'Niveau 1'}>Niveau 1</MenuItem>
        <MenuItem value={'Niveau 2'}>Niveau 2</MenuItem>
        <MenuItem value={'Niveau 3'}>Niveau 3</MenuItem>
        <MenuItem value={'Niveau 4'}>Niveau 4</MenuItem>
        <MenuItem value={'Niveau 5'}>Niveau 5</MenuItem>
        <MenuItem value={'Niveau 7'}>Niveau 7</MenuItem>
        <MenuItem value={'Niveau 8'}>Niveau 8</MenuItem>
        <MenuItem value={'Niveau 9'}>Niveau 9</MenuItem>
       <MenuItem value={'Niveau 10'}>Niveau 10</MenuItem>
       <MenuItem value={'Niveau 11'}>Niveau 11</MenuItem>
       <MenuItem value={'Niveau 12'}>Niveau 12</MenuItem>
       <MenuItem value={'Niveau 13'}>Niveau 13</MenuItem>
          </Select>
        </FormControl>
      </Grid>
     <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140, background: 'white' }}>
          <Select
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
             Sélectionner une rubrique
            </MenuItem>
            <MenuItem value={1}>Section A</MenuItem>
            <MenuItem value={2}>Section B</MenuItem>
            <MenuItem value={3}>Section C</MenuItem>
            <MenuItem value={3}>Section D</MenuItem>
            <MenuItem value={3}>Section E</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      &nbsp; &nbsp;
      <Box sx={{ width: '20%', marginTop: '1.2%',}}>
        <CustomSearchBar  title={"Recherche étudiant"} />
      </Box>
      <Box sx={{ flexGrow: 1}}>
        <Button
          variant="contained"
          style={{ minHeight: '50px', minWidth: '45px', backgroundColor: '#000000',  marginTop: '3%' }}
        >
          <SearchIcon />
        </Button>
      </Box>

      <Grid item sx={{mb: 2}}>
      <Grid item sx={{mb: 2,position:"relative",width:"9rem"}}>
     <FormControl sx={{ minWidth: 140, position:"absolute",left:"0rem",top:"0rem" }}>
          <Select
            value={selectedValue}
            onChange={handleSelectChange1}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 120,
              p: 1,
            }}
          >
          
          
           {/* <p  onMouseLeave={()=>{setMainMenuOpen(false)}}>  */}
            <MenuItem value="">
            Filtrer par
            </MenuItem>
            <MenuItem   style={{display:"none"}} onClick={(e)=>{setLevelFilter(true);setGenderFilter(false); setStatusFilter(false);setMainMenuOpen(true);setSelectedValue(e.target.value)}} value={"Nivieau"}>Nivieau</MenuItem>
            <MenuItem  style={{display:"none"}} onClick={(e)=>{setLevelFilter(false);setGenderFilter(true); setStatusFilter(false);setMainMenuOpen(true);setSelectedValue(e.target.value)}} value={"Genre"}>Genre</MenuItem>
            <MenuItem   onClick={(e)=>{setLevelFilter(false);setGenderFilter(false); setStatusFilter(true);setMainMenuOpen(true);setSelectedValue(e.target.value)}} value={"Statut"}>Statut</MenuItem>

            <MenuItem style={{display:"none"}} value={'Niveau 1'}>Niveau 1</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 2'}>Niveau 2</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 3'}>Niveau 3</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 4'}>Niveau 4</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 5'}>Niveau 5</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 7'}>Niveau 7</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 8'}>Niveau 8</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 9'}>Niveau 9</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 10'}>Niveau 10</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 11'}>Niveau 11</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 12'}>Niveau 12</MenuItem>
        <MenuItem style={{display:"none"}} value={'Niveau 13'}>Niveau 13</MenuItem>

        <MenuItem style={{display:"none"}}   value={"Mâle"}>Mâle</MenuItem>
        <MenuItem style={{display:"none"}}   value={"Femelle"}>Femelle</MenuItem>


        <MenuItem style={{display:"none"}}  value={"Payé"}>Payé</MenuItem>
        <MenuItem style={{display:"none"}}  value={"Non payé"}>Non payé</MenuItem>

           {/*</p>*/}

          </Select>
        </FormControl>


                 {/*1 LEVEL FILTER DROPDOWN */}
                 {/* levelFilter &&
        <div style={{  position:"absolute",left:"1rem",top:"4rem",backgroundColor:"white"}}>
        <FormControl style={{ width: 120}}>
          <Select onMouseLeave={()=>{setLevelFilter(false)}}
            value={selectedLevel}
            onChange={handleSelectLevelChange}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 120,
              p: 1,
            }}
          >
            <MenuItem onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={false}>effacer le filtre</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 1"}>Niveau 1</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 2"}>Niveau 2</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 3"}>Niveau 3</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 4"}>Niveau 4</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 5"}>Niveau 5</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 6"}>Niveau 6</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 7"}>Niveau 7</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 8"}>Niveau 8</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 9"}>Niveau 9</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 10"}>Niveau 10</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 11"}>Niveau 11</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 12"}>Niveau 12</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 13"}>Niveau 13</MenuItem>

            
           
          </Select>
        </FormControl>
        </div>
        */ }



                           {/*2 GENDER FILTER DROPDOWN */}
                             {/* genderFilter &&
                                  <div style={{  position:"absolute",left:"1rem",top:"4rem",backgroundColor:"white"}} >
                                     <FormControl sx={{ width: 120 }}>
                                       <Select  onMouseLeave={()=>{setGenderFilter(false)}}
                                         value={selectedGender}
                                         onChange={handleSelectGenderChange}
                                         displayEmpty
                                         label=""
                                         sx={{
                                         //   minHeight: 30,
                                           minWidth: 120,
                                           p: 1,
                                         }}
                                       >
                                        
                                        <MenuItem onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={false}>effacer le filtre</MenuItem>
                                         <MenuItem  onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={"Mâle"}>Mâle</MenuItem>
                                         <MenuItem  onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={"Femelle"}>Femelle</MenuItem>
                                        
                                       </Select>
                                     </FormControl>
                                 </div>
                                        */}
                             


                      {/*3 STATUS FILTER DROPDOWN */}
                     { statusFilter &&
                         <div style={{  position:"absolute",left:"1rem",top:"4rem",backgroundColor:"white"}} >
                             <FormControl sx={{ minWidth: 140}}>
                               <Select  onMouseLeave={()=>{setStatusFilter(false)}}
                                 value={selectedStatus}
                                 onChange={handleSelectStatusChange}
                                 displayEmpty
                                 label=""
                                 sx={{
                                 //   minHeight: 30,
                                   minWidth: 120,
                                   p: 1,
                                 }}
                               >
                                
                                <MenuItem onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={false}>effacer le filtre</MenuItem>
                                 <MenuItem onClick={()=>{setStatusFilter(false);setMainMenuOpen(false)}} value={"Payé"}>Payé</MenuItem>
                                 <MenuItem onClick={()=>{setStatusFilter(false);setMainMenuOpen(false)}} value={"Non Payé"}>Non Payé</MenuItem>
                                
                               </Select>
                             </FormControl>
                          </div>   
                              }


      </Grid>
      </Grid>

            <Grid item xs={8} md={12} lg={12}>
            <div id="printableArea" style={{background: '#F8F8F8',  padding: '10px'}}>
           <StudentFinanceStats students={studentsToDisplay}/> 
            </div>
            </Grid>
            
          </Grid>
          {/* <Grid item xs={8} md={12} lg={12}>
              <div style={{background: '#F8F8F8',  padding: '10px'}}>
                <CustomChart headerOne={"Statistiques"} headerTwo={'Earnings & Expense'}  value={"200"} type={"two"}/>
                </div>
            </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
